.error {
    padding: 145px 50px 100px;
    background-color: $greyBg;

    &__container {
        max-width: 1170px;
        background-color: $white;
        margin: auto;
        padding: 5.34em 6.67em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }

    &__header {
        color: $blue;
        font-size: 2.5em;
        font-family: $inter;
        font-weight: 400;
    }

    &__subheader {
        font-size: 1.4em;
        font-family: $inter;
        font-weight: 500;
        margin-top: 20px;
    }

    &__header,
    &__subheader {
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        padding-top: 100px;

        &__container {
            padding: 3em 1em;
            text-align: center;
        }
    }
}

.error-search {
    background-color: $greyBg;
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    width: 350px;
    margin-top: 40px;

    input {
        background-color: $greyBg;
        height: 100%;
        width: 100%;
        padding-left: 10px;
        font-family: $inter;
        color: rgb(98, 98, 98);
        border: none;
        outline: none;
    }

    button {
        cursor: pointer;
    }

    .search-icon {
        height: 20px;
    }

    @media screen and (max-width: 768px) {
        width: 250px;
        margin: auto;
    }
}
