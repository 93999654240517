.news {
    background-color: $greyBg;
    padding: 20px;
    padding-top: 115px;

    &__container {
        background-color: $white;
        padding: 50px 30px;
        max-width: 1100px;
        margin: auto;
    }

    &__header {
        font-family: $mont;
        color: $blackHeader;
        font-size: 2.5em;
        line-height: 48px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    &__date {
        color: $blue;
        font-family: $opensans;
        font-size: 0.94em;
        font-weight: 300;
        margin-bottom: 30px;
    }

    &__img {
        height: auto;
        width: 100%;
        margin-bottom: 30px;
    }

    &__body {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $mont;
            color: $blackHeader;
            line-height: 32px;
        }

        h1 {
            font-size: 2.5em;
        }

        h2 {
            font-size: 1.95em;
        }

        h3 {
            font-size: 1.4em;
        }

        h4 {
            font-size: 1.25em;
        }

        h5 {
            font-size: 1.15em;
        }

        h6 {
            font-size: 1.05em;
        }

        strong {
            font-weight: 600;
        }

        p,
        ol {
            font-family: $opensans;
            color: $blackBg;
            font-size: 0.94em;
            line-height: 30px;
        }

        ol {
            margin-left: 5%;
        }
    }

    .btn {
        background-color: rgb(129, 138, 145);
        text-decoration: none;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin: auto;
        padding: 12px 24px;
        border-radius: 5px;
        font-family: $opensans;
        margin-top: 30px;

        &:active {
            color: $white;
        }

        &:visited {
            color: $white;
        }
    }

    @media screen and (max-width: 768px) {
        &__header {
            font-size: 1.56em;
            line-height: 30px;
        }
    }
}
