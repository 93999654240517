.map {
    height: 500px;

    iframe {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 768px) {
        height: 380px;
    }
}
