.oceanscout {
    font-family: 'Inter', sans-serif;
    color: white;
    overflow-x: hidden;

    @include reset;

    .nav {
        width: 100%;
        height: 80px;
        background-color: #000211;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1500px;
        margin: auto;

        &__container {
            background-color: #000211;
            width: 100%;
            padding: 0 3%;
        }

        .mobile-link {
            display: none;
        }

        .right {
            gap: 2em;
        }

        .contact {
            margin-right: 15px;
        }

        .oceanscout {
            color: white;
            border-radius: 27px;
            padding: 12px 30px;
            width: fit-content;
            display: inline-flex;
            background-color: rgb(237, 83, 63);

            &:hover::after {
                width: 0;
            }
        }

        .oceanscout-li {
            max-width: none !important;
        }
    }
    // .nav > .nav-header {
    //     flex: 0.95;
    // }
    .nav > .nav-header > .nav-title {
        color: #fff;
        font-size: 1em;

        img {
            width: 130px;
        }
    }

    .nav-header {
        display: flex;
    }

    .nav-list {
        display: flex;
        gap: 2rem;
    }
    .nav-list > li {
        list-style-type: none;
        max-width: 156px;
        display: flex;
        align-items: center;
    }

    .nav-list > li a {
        text-decoration: none;
        color: #efefef;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -2px;
            width: 0px;
            height: 2px;
            background: #eee;
            transition: all 0.45s;
        }

        &:hover::after {
            width: 100%;
            left: 0;
        }
    }
    .nav > #nav-check {
        display: none;
    }

    @media (max-width: 1024px) {
        .nav > .nav-list {
            color: #fff;
            font-size: 0.85em;
        }
        .nav {
            .middle {
                padding-left: 8%;
            }

            .contact {
                margin-right: 15px;
            }
        }

        .nav {
            padding: 1rem;

            .mobile-link {
                display: block;
            }
        }
        .nav > .nav-btn {
            display: inline-block;
            right: 0;
            top: 0;
            padding-top: 0.2rem;
        }
        .nav > .nav-btn > label {
            display: inline-block;
            width: 50px;
            height: 50px;
            padding: 13px;
        }
        .nav > .nav-btn > label > span {
            display: block;
            width: 25px;
            height: 10px;
            border-top: 2px solid #eee;
            z-index: 1000;
        }
        .nav-list {
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            background-color: #000210;
            height: 0;
            transition: all 0.3s ease-in;
            top: 40px;
            left: 0;
            overflow: hidden;
            left: -4%;
        }
        .nav-list > li {
            width: 100%;
            margin-top: 1.9rem;
        }
        .nav > #nav-check:checked ~ .nav-list {
            height: fit-content;
            z-index: 1;
            margin-top: 16%;
            padding-bottom: 30px;
        }

        .header {
            display: none;
        }
    }

    .header {
        height: 80px;
        background-color: #000210;
        color: white;
        padding: 0 3%;

        &__container {
            height: 100%;
            width: 100%;
            margin: auto;
            max-width: 1500px;
            display: flex;
            flex-basis: row nowrap;
            justify-content: space-between;
            align-items: center;
        }

        &__header {
            font-size: 24px;
            font-weight: 500;
        }

        &__content {
            width: 100%;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &__link {
            text-decoration: none;
            color: white;
            font-size: 16px;
        }

        &__quote {
            color: rgba(237, 83, 63, 1);
            border: 1px solid #ed533f;
            border-radius: 5px;
            padding: 10px 20px;
            margin-left: 2%;
            font-weight: 500;
            cursor: pointer;
        }

        &__overview {
            opacity: 0.6;
        }
    }

    .hero {
        background-color: rgb(0, 2, 16);
        height: 100vh;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 50% 13vh;

        &__container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            top: 50%;
            transform: translateY(-50%);
        }

        &__header {
            font-size: 4.5em;
            font-weight: 500;
        }

        &__subheader {
            opacity: 0.8;
            font-size: 28px;
            font-weight: 300;
            width: 50%;
            text-align: center;
            margin-top: 20px;
            line-height: 44px;
        }
    }

    #bubbles {
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
    }

    .video {
        &__container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            position: relative;
            background-color: #000211;
        }

        &__glider-container {
            rotate: 90deg;
            left: -75%;
            position: absolute;
        }

        &__video {
            background-color: #000211;
            height: 95%;
            width: 95%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border-radius: 10px;
        }

        &__video-container {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__text {
            font-size: 50px;
            position: absolute;
            opacity: 0;
            z-index: 1;
        }

        .video-background {
            align-self: normal;
        }
    }

    .pin,
    .dissect {
        &__container {
            height: 100vh;
            background-color: rgb(0, 2, 16);
            margin: auto;
            padding-top: 50px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
        }

        &__container-wrapper {
            max-width: 1500px;
            margin: auto;
        }

        &__header-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }

        &__subheader {
            color: #3ec74c;
            font-family: 'Nitti';
            font-weight: 400;
            font-style: normal;
            font-size: 28px;
        }

        &__header {
            font-size: 53px;
            max-width: 663px;
            text-align: center;
            color: rgba(138, 139, 145, 1);

            .white {
                color: white;
            }
        }

        &__bottom {
            display: flex;
            margin: auto;
            width: 80%;
            margin-top: 15%;
            max-width: 1500px;
            gap: 50px;
        }

        &__img-container {
            width: 100%;
            height: 300px;
            position: absolute;
            top: -100px;
            opacity: 0;
        }

        &__left {
            width: 50%;
            display: flex;

            img {
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: contain;
            }
        }

        &__right {
            width: 50%;
        }

        &__paragraph {
            opacity: 1;
            position: absolute;
        }

        &__title {
            color: #3ec74c;
            font-family: 'Nitti';
            font-weight: 500;
            font-style: normal;
            font-size: 24px;
            margin-bottom: 10px;
        }

        &__body {
            line-height: 32.13px;
            font-size: 18px;
        }
    }

    .background-container {
        background: linear-gradient(180deg, #000211 0%, #033145 100%);
    }

    .dissect {
        &__container {
            height: 100vh;
            display: flex;
            justify-content: center;
            flex-direction: column;
            background-image: none;
            background-color: unset;
        }

        &__top {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 20px;
            height: 20%;
        }

        &__subheader {
            font-family: 'Nitti';
            color: #3ec74c;
            font-size: 28px;
        }

        &__header {
            font-size: 50px;
            color: rgba(138, 139, 145, 1);
        }

        .white {
            color: white;
        }

        &__bottom {
            display: flex;
            flex-direction: column-reverse;
            max-width: 1100px;
        }

        &__img-container {
            opacity: 1;
            height: 70vh;
            top: -330px;
            right: -620px;

            @media screen and (max-width: 1200px) {
                right: -600px;
            }
            @media screen and (max-width: 1024px) {
                right: -400px;
            }
        }

        &__left {
            width: 50%;

            img {
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: contain;
            }
        }

        &__right {
            width: 50%;
        }
    }

    .imageBlock {
        height: 100vh;
        padding: 40px;

        &__container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            gap: 70px;
            max-width: 1500px;
            margin: auto;
        }

        &__left {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &__header {
            font-size: 40px;
            line-height: 61px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        &__body {
            font-size: 24px;
            font-weight: 500;
            line-height: 42px;
            opacity: 0.8;
        }

        &__right {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__img-container {
            width: 40vw;
            height: 80%;
            border-radius: 10px 10px 10px 100px;
            overflow: hidden;
            max-width: 750px;
            max-height: 900px;

            img {
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
            }
        }
    }

    .imageStack {
        &__container {
            height: 100vh;
            display: flex;
            align-items: center;
            max-width: 1500px;
            margin: auto;
        }

        &__left-container {
            display: none;
        }

        &__left {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .mobile {
            display: none;
        }

        &__right {
            width: 50%;
            margin: 0 5%;
        }

        &__wrapper {
            max-width: 512px;
            max-height: 560px;
            opacity: 1;
            position: absolute;
            margin: auto;
            border-radius: 10px 10px 100px 10px;
            display: flex;
            align-items: flex-end;
            opacity: 0;
            margin-left: 5%;
            height: 50vh;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: 65% 0 !important;
        }

        .first {
            background-position: 10% !important;
            background-repeat: no-repeat !important;
            background-size: 100% !important;
        }

        &__text {
            margin: 0 0 20px 20px;
        }

        &__title {
            font-family: 'lato', sans-serif;
            font-weight: 700;
            font-size: 28px;
            margin-bottom: 10px;
        }

        &__body {
            font-size: 20px;
            line-height: 31.9px;
            width: 95%;
        }

        &__static-header {
            font-family: 'lato', sans-serif;
            font-weight: 800;
            font-size: 45px;
            line-height: 61px;
            margin-bottom: 10px;
        }

        &__static-subheader {
            font-weight: 500;
            font-size: 18px;
            line-height: 38px;
        }
    }

    .slider {
        &__container {
            height: 100vh;
            background: linear-gradient(
                180deg,
                rgba(3, 49, 69, 1) 50%,
                rgba(0, 2, 17, 1) 50%
            );
            padding: 5%;
            padding-right: 0;
            width: 102%;
        }

        &__header {
            background: linear-gradient(
                90.97deg,
                rgba(20, 153, 161, 1),
                rgba(52, 104, 177, 1)
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-weight: 700;
            font-size: 60px;
            margin-bottom: 40px;
            line-height: normal;
        }

        .mobile {
            display: none;
        }

        &__wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            flex-grow: 0;
            gap: 5%;
        }

        &__item {
            min-width: 600px;
            height: 70vh;
            width: 70vh;
            max-height: 600px;
            background-color: rgba(20, 26, 37, 1);
            box-shadow: 0px 4px 50px -15px rgba(0, 61, 192, 0.25);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: end;
            padding: 0 0 2% 2%;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: 65% 0 !important;
        }

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 10px;
        }

        &__paragraph {
            color: rgba(255, 255, 255, 0.8);
            font-size: 22px;
            line-height: 34px;
            margin-right: 10%;
        }
    }

    .slider-section {
        margin-inline: auto;
        padding-block: 5rem;
        background-color: #000211;

        .slider-wrapper {
            max-width: 1500px;
            margin: auto;
        }

        .mission-control {
            margin-left: 5%;
            font-size: 60px;
            width: 40%;
            margin-bottom: 30px;
            color: rgba(255, 255, 255, 0.54);
            @media screen and (max-width: 1200px) {
                width: 50%;
            }
            @media screen and (max-width: 1024px) {
                font-size: 40px;
            }

            @media screen and (max-width: 768px) {
                width: 80%;
            }
        }

        .white {
            color: white;
        }

        a,
        button {
            cursor: pointer;
            user-select: none;
            border: none;
            outline: none;
            background: none;
        }

        img {
            display: block;
            max-width: 100%;
            // height: auto;
            object-fit: cover;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: -moz-crisp-edges;
            image-rendering: crisp-edges;
            height: 74vh;
        }

        .swiper {
            &-button-next::after,
            &-button-prev::after {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.75rem;
                font-weight: 800;
                padding: 1rem;
                width: 2rem;
                height: 2rem;
                opacity: 0.75;
                border-radius: 50%;
                color: white;
            }
        }

        .swiper-slide {
            border-radius: 10px;
        }

        .swiper-pagination {
            display: none;
        }

        .second {
            object-position: bottom;
        }

        .nav-container {
            display: flex;
            justify-content: space-between;
        }

        .container {
            max-width: 1500px;
            width: 90%;
            height: auto;
            margin-inline: auto;
        }
    }

    .clickSwitch {
        height: 90vh;
        background: #000211;
        padding-top: 4vh;

        .mobile {
            display: none;
        }

        &__container {
            display: flex;
            flex-direction: row;
            height: 100%;
            gap: 20px;
            width: 90%;
            max-width: 1500px;
            margin: auto;
        }

        &__left {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 40px;
            align-items: center;
            justify-content: start;
        }

        &__block {
            height: fit-content;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.4);
            transition: 0.3s;
        }

        &__header {
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .active-block {
            color: white;

            h1 {
                font-weight: 700;
            }
        }

        &__paragraph {
            font-weight: 500;
            font-size: 24px;
            line-height: 34px;
        }

        &__right {
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: end;
            justify-content: flex-start;
        }

        &__img-container {
            width: 42vw;
            height: 53vh;
            position: absolute;
            opacity: 0;
            transition: 0.3s;
            border-collapse: separate;
            border-radius: 10px;
            overflow: hidden;
            max-width: 700px;

            img {
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
            }
        }

        .active-img {
            opacity: 1;
            z-index: 2;
        }
    }

    .grid {
        height: 130vh;
        background: #000211;
        padding: 3%;
        padding-bottom: 150px;

        &__container {
            max-width: 1500px;
            margin: auto;
            height: 100%;
        }

        &__header {
            font-size: 60px;
            margin-bottom: 2%;
        }

        &__top {
            display: flex;
            justify-content: space-between;
        }

        &__cta {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            margin-bottom: 24px;
            cursor: pointer;
            text-align: center;
            text-decoration: none;
        }

        &__grid {
            height: 100%;
            display: flex;
            flex-flow: row wrap;
            gap: 19px;
        }

        &__link {
            width: 49%;
            color: white;
            text-decoration: none;
            border-radius: 10px;
        }

        &__tag {
            color: white;
            background-color: #3ec74c;
            font-size: 18px;
            width: fit-content;
            padding: 15px;
            border-radius: 5px;
            margin: 3% 0 0 3%;
        }

        &__item {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            border-radius: 10px;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: 65% 0 !important;
        }

        &__content {
            margin: 0 3% 5% 3%;
        }

        &__title {
            font-size: 30px;
            line-height: 42px;
            font-weight: 700;
            margin-bottom: 10px;
            width: 70%;
        }

        &__paragraph {
            font-size: 18px;
            line-height: 143%;
            margin-bottom: 20px;
        }

        &__learn-more-container {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        &__learn-more {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .footer {
        padding: 3%;
        background: #000211;

        &__container {
            display: flex;
            flex-direction: row;
            border: 1px solid rgba(255, 255, 255, 0.12);
            // padding: 3%;
        }

        &__left {
            width: 45%;
            border-right: 1px solid rgba(255, 255, 255, 0.12);
            padding: 3%;
        }

        &__middle {
            display: flex;
            flex-direction: column;
            padding: 3% 0 1.5% 0;
            border-right: 1px solid rgba(255, 255, 255, 0.12);
            width: 30%;

            .footer__links {
                padding-bottom: 61px;
            }

            .footer__header {
                padding-left: 40px;
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            padding: 3% 0 1.5% 0;
            width: 30%;

            .footer__links {
                padding-bottom: 92px;
            }

            .footer__header {
                padding-left: 40px;
            }
        }

        img {
            width: 200px;
            margin-bottom: 30px;
        }

        &__header {
            font-size: 16px;
            opacity: 0.8;
            font-family: 'Nitti';
            margin-bottom: 15px;
        }

        &__paragraph {
            font-size: 18px;
            line-height: 31px;
        }

        &__links {
            display: flex;
            flex-direction: column;
            gap: 15px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            padding-left: 40px;
        }
        a {
            font-family: 'Nitti';
            text-decoration: none;
            color: white;

            &:hover {
                color: rgba(62, 199, 76, 1);
            }
        }

        &__email {
            margin-top: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        }

        &__location {
            margin-top: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        }

        &__phone {
            margin-top: 20px;
        }

        &__linkedin {
            margin-top: 20px;

            a {
                padding-left: 40px;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .imageStack {
            &__wrapper {
                height: 46vh;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .hero {
            &__container {
                text-align: center;
            }

            &__subheader {
                width: 80%;
            }
        }

        .pin,
        .dissect {
            &__body {
                font-size: 16px;
            }
        }

        .imageBlock {
            &__header {
                font-size: 28px;
                line-height: 40px;
            }

            &__body {
                font-size: 20px;
            }
        }

        .imageStack {
            &__static-header {
                font-size: 32px;
                line-height: 48px;
            }

            &__static-subheader {
                line-height: 31px;
            }

            &__wrapper {
                height: 40vh;
            }
        }
        &__title {
            font-size: 24px;
        }

        &__body {
            font-size: 18px;
        }

        .grid {
            &__grid {
                gap: 14px;
            }

            &__header {
                font-size: 44px;
            }

            &__cta {
                font-size: 20px;
            }

            &__title {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 5px;
            }

            &__paragraph {
                line-height: 30px;
            }
        }
    }

    @media screen and (max-width: 890px) {
        .imageStack {
            &__wrapper {
                height: 36vh;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .hero {
            height: 50vh;
            background-image: none !important;

            &__header {
                font-size: 1.875em;
            }

            &__subheader {
                font-size: 1.1em;
                line-height: 26.28px;
            }
        }

        .video {
            &__container {
                height: 40vh;
            }

            .video-background {
                border-radius: 10px;
            }
        }

        .pin,
        .dissect {
            &__container {
                height: unset;
                width: 100vw;
                background-image: none !important;
            }

            &__bottom {
                margin-top: 5%;
                gap: 20px;
                flex-direction: column;
                width: 90%;
            }

            &__img-container {
                opacity: 1;
                position: relative;
                &.mobile {
                    display: none;
                }
            }

            &__left {
                margin: unset;
                width: unset;

                img {
                    position: relative;
                    height: 100%;
                    margin-top: 57px;
                }
            }

            &__right {
                width: 100%;
            }

            &__paragraph {
                position: unset;
                margin-bottom: 60px;
            }

            &__body {
                font-size: 18px;
            }

            &__title {
                font-size: 22px;
            }
        }

        .dissect {
            &__top {
                top: 50px;
            }

            &__img-container {
                top: 0;
                right: -43px;
                transform: rotate(62deg);
                height: 350px;
            }
        }

        .imageBlock {
            padding-left: 5%;
            padding-right: 5%;

            &__container {
                flex-direction: column;
                gap: 10px;
            }

            &__left {
                width: 100%;
                align-items: flex-start;
            }

            &__header {
                font-size: 24px;
                line-height: 34px;
            }

            &__body {
                font-size: 18px;
                line-height: 33px;
            }

            &__right {
                width: 100%;
                height: 120%;
            }

            &__img-container {
                width: 100%;
                border-radius: 10px;
            }
        }

        .imageStack {
            height: 80vh;
            width: 100vw;

            &__container {
                flex-direction: column-reverse;
                height: unset;
                gap: 30px;
            }

            &__left-container {
                width: 100%;
                overflow-x: scroll;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }

            &__left-container::-webkit-scrollbar {
                width: 0;
            }

            .mobile {
                display: flex;
            }

            .desktop {
                display: none;
            }

            &__left {
                width: 150%;
                justify-content: space-evenly;
                padding-right: 5%;
            }

            &__wrapper {
                height: 48vh;
                width: 90vw;
                opacity: 1;
                position: unset;
                border-radius: 10px;
                margin-left: 3%;
                min-width: 300px;
                margin: auto;
                max-width: unset;
            }

            &__text {
                margin: 0 0 15px 13px;
            }

            &__title {
                font-size: 22px;
                margin-bottom: 5px;
            }

            &__body {
                font-size: 16px;
                line-height: 26.9px;
            }

            &__right {
                width: 90%;
            }
        }

        .slider {
            .desktop {
                display: none;
            }

            .mobile {
                display: flex;
            }

            &__container {
                padding-left: 0;
                height: auto;
            }

            &__header {
                margin-left: 5%;
                font-size: 40px;
            }

            &__wrapper-container {
                width: 100%;
                overflow-x: scroll;
                background: linear-gradient(180deg, #033145 46%, #000211 46%);
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }

            &__wrapper-container::-webkit-scrollbar {
                width: 0;
            }

            &__wrapper {
                gap: 1%;
                padding-left: 5%;
                padding-right: 5%;
                @media screen and (max-width: 680px) {
                    padding-right: 15%;
                }
            }

            &__item {
                min-width: 400px;
                height: 50vh;
                width: 60vw;
                padding: 0 0 1% 1%;
            }

            &__title {
                font-size: 22px;
                margin-bottom: 0;
            }

            &__paragraph {
                font-size: 16px;
                line-height: 26px;
            }
        }

        .clickSwitch {
            height: unset;

            .desktop {
                display: none;
            }

            &__left {
                width: 100%;
                pointer-events: none;
                color: white;
            }

            &__block {
                color: white;
            }

            &__paragraph {
                margin-bottom: 20px;
            }

            &__img-container.mobile {
                display: block;
                position: relative;
                opacity: 1;
                height: 350px;
                width: 100%;
                margin-bottom: 60px;
            }
        }

        .grid {
            height: 100%;
            padding-bottom: 40px;
            padding-top: 20%;

            &__header {
                font-size: 40px;
                margin-bottom: 20px;
            }

            &__grid {
                gap: 30px;
            }

            &__link {
                width: 100%;
                height: 500px;
            }
        }

        .footer {
            &__container {
                flex-direction: column;
            }

            &__left {
                width: 100%;
            }

            &__middle {
                width: 100%;
            }

            &__right {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 500px) {
        .video {
            &__text {
                font-size: 38px;
            }
        }

        .pin,
        .dissect {
            &__subheader {
                font-size: 20px;
            }

            &__header {
                font-size: 28px;
                line-height: 37px;
            }
        }

        .imageStack {
            &__left {
                width: 245%;
            }
        }

        .slider {
            &__header {
                margin-bottom: 50px;
                margin-top: 60px;
            }

            &__item {
                width: 94vw;
                min-width: unset;
            }
        }
    }
}
