@import 'base/*';
@import 'partials/cookies.scss';
@import 'partials/heroes/landingHero.scss';
@import 'partials/heroes/hero.scss';
@import 'partials/heroes/oceanscoutHero.scss';
@import 'partials/footer.scss';
@import 'partials/nav.scss';
@import 'partials/newsArticles.scss';
@import 'partials/modules/contactInformation.scss';
@import 'partials/modules/diagonalImages.scss';
@import 'partials/modules/entrySlider.scss';
@import 'partials/modules/gallery.scss';
@import 'partials/modules/imageAnimate.scss';
@import 'partials/modules/map.scss';
@import 'partials/modules/textImage.scss';
@import 'partials/modules/videoLoop.scss';
@import 'partials/oceanscoutModules/clickShow.scss';
@import 'partials/oceanscoutModules/horizontalScroller.scss';
@import 'partials/oceanscoutModules/imageBlock.scss';
@import 'partials/oceanscoutModules/imageFade.scss';
@import 'partials/oceanscoutModules/resources.scss';
@import 'partials/oceanscoutModules/scrollFade.scss';
@import 'partials/oceanscoutModules/slider.scss';
@import 'partials/oceanscoutModules/video.scss';
@import 'partials/modules/splitHeaderBody.scss';
@import 'singles/newsLanding.scss';
@import 'singles/home.scss';
@import 'singles/404.scss';
@import 'singles/results.scss';
@import 'singles/oceanscout.scss';
@import 'Oceanscout/oceanscout.scss';

.oceanscout-landing {
    overflow-x: hidden;
}