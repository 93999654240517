.slider-section {
    margin-inline: auto;
    padding-block: 5rem;
    background-color: #000211;
    font-family: $inter;

    .slider-wrapper {
        margin: auto;

        @media screen and (min-width: 1500px) {
            max-width: 1700px;
        }
    }

    .mission-control {
        margin-left: 5%;
        font-size: 60px;
        margin-bottom: 30px;
        color: rgba(255, 255, 255, 0.54);

        @media screen and (max-width: 1024px) {
            font-size: 40px;
        }
    }

    .white {
        color: white;
    }

    a,
    button {
        cursor: pointer;
        user-select: none;
        border: none;
        outline: none;
        background: none;
    }

    img {
        display: block;
        max-width: 100%;
        // height: auto;
        object-fit: cover;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: -moz-crisp-edges;
        image-rendering: crisp-edges;
        height: 74vh;
    }

    .swiper {
        &-button-next::after,
        &-button-prev::after {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.75rem;
            font-weight: 800;
            padding: 1rem;
            width: 2rem;
            height: 2rem;
            opacity: 0.75;
            border-radius: 50%;
            color: white;
        }
    }

    .swiper-slide {
        border-radius: 10px;
    }

    .swiper-pagination {
        display: none;
    }

    .second {
        object-position: bottom;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
        color: $white;
        font-size: 1.3em;
        width: 97%;
        margin: 20px auto 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
        cursor: pointer;
    }

    .container {
        max-width: 1500px;
        width: 90%;
        height: auto;
        margin-inline: auto;
    }
}
