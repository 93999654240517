html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input,
textarea,
select,
button,
main {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    position: relative;
    margin-block-start: 0;
    margin-block-end: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
picture {
    display: block;
}
body {
    line-height: 1;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
th {
    text-align: left;
}
button {
    background: none;
    outline: inherit;
}
input[type='submit'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
em,
strong,
ul,
li,
p {
    margin: 0;
}
