.imageBlock {
    height: 100vh;
    padding: 40px;
    font-family: $inter;
    color: $white;

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        gap: 70px;
        max-width: 1500px;
        margin: auto;
        justify-content: center;
    }

    &__left {
        width: 40%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    &__header {
        font-size: 40px;
        line-height: 61px;
        font-weight: 700;
        margin-bottom: 20px;

        @media screen and (max-width: 1560px) {
            width: 85%;
        }
    }

    &__body {
        font-weight: 500;
        opacity: 0.8;
        line-height: 32.13px;
        font-size: 18px;
    }

    &__right {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img-container {
        width: 40vw;
        height: 80%;
        border-radius: 10px 10px 10px 100px;
        overflow: hidden;
        max-width: 750px;
        max-height: 500px;

        img {
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
    }

    @media screen and (max-width: 1024px) {
        &__header {
            font-size: 28px;
            line-height: 40px;
        }

        &__body {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 768px) {
        padding-left: 5%;
        padding-right: 5%;

        &__container {
            flex-direction: column;
            gap: 10px;
        }

        &__left {
            width: 100%;
            align-items: flex-start;
        }

        &__header {
            font-size: 24px;
            line-height: 34px;
        }

        &__body {
            font-size: 18px;
            line-height: 33px;
        }

        &__right {
            width: 100%;
            height: 120%;
        }

        &__img-container {
            width: 100%;
            border-radius: 10px;
        }
    }
}
