.diagonal {
    padding: 5%;
    padding-top: 0;
    padding: 0 5% 3.5%;
    background-color: #000210;
    font-family: $inter;
    color: $white;
    overflow-x: hidden;
    top: -1px;
    max-height: 700px;

    &__container {
        display: flex;
        flex-direction: row;
        max-width: 1350px;
        margin: auto;
        background-color: #171a1d;
    }

    &__left {
        width: 40%;
        padding: 50px 0 50px 50px;
    }

    &__tag {
        color: white;
        background-color: #3ec74c;
        font-size: 18px;
        width: fit-content;
        padding: 10px 15px;
        border-radius: 5px;
        margin-bottom: 25px;
    }

    &__header,
    &__subheader {
        font-size: 2em;
        font-weight: 700;
    }

    &__header {
        margin-bottom: 10px;
    }

    &__subheader {
        color: rgba(138, 139, 145, 1);
        margin-bottom: 10px;
        width: 142%;
        @media screen and (max-width: 1300px) {
            width: 100%;
            line-height: 42px;
        }
    }

    &__body {
        font-weight: 500;
        line-height: 31.9px;
        opacity: 0.75;
        font-size: 1.25em;
        margin-bottom: 25px;
        width: 120%;
    }

    &__link {
        color: $white;
        text-decoration: none;
        font-weight: 700;

        svg {
            margin-left: 5px;
            transition: transform 0.15s ease;
        }

        &:hover {
            svg {
                transform: translateX(10px);
            }
        }

        &:after {
            color: $white;
        }

        &:visited {
            color: $white;
        }
    }

    &__right {
        width: 60%;
        display: flex;
        flex-direction: row;
        gap: 3%;
        overflow: hidden;
    }

    &__img-container {
        width: 20%;
        height: 100%;
        transform: skew(-28deg);
        right: -31%;
        overflow: hidden;

        img {
            position: absolute;
            height: 100%;
            width: 300%;
            left: -97%;
            top: 0;
            object-fit: cover;
            z-index: 5;

            @media screen and (max-width: 1200px) {
                width: 344%;
                left: -118%;
            }
        }
    }

    &__img-container > * {
        transform: skew(28deg);
    }

    @media screen and (max-width: 1024px) {
        margin-top: 31px;

        &__container {
            flex-direction: column;
        }

        &__left,
        &__right {
            width: 100%;
        }

        &__left {
            padding: 50px;
        }

        &__right {
            height: 300px;
        }

        &__body {
            width: 100%;
        }

        &__img-container {
            transform: skew(-38deg);
            right: 0;
            overflow: hidden;

            img {
                width: 300%;
                left: -97%;
                @media screen and (max-width: 1200px) {
                    width: 344%;
                    left: -118%;
                    transform: skew(38deg);
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        max-height: none;
        padding: 5%;

        &__container {
            overflow-x: hidden;
        }

        &__left {
            padding: 20px;
        }

        &__body {
            width: 100%;
        }

        &__header {
            font-size: 1.45em;
        }

        &__subheader {
            font-size: 1.45em;
            margin-bottom: 15px;
            line-height: 28px;
        }

        &__right {
            width: 150%;
            left: -10%;
        }
    }
}
