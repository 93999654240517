.textImage {
    background-color: $blackBg;

    &__container {
        max-width: 1500px;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 60px 10%;
        gap: 1em;

        &.reverse {
            flex-direction: row-reverse;
            gap: 30px;
        }
    }

    &__left {
        width: 50%;
    }

    &__right {
        width: 50%;
    }

    &__header {
        font-family: $titti;
        font-size: 2.375em;
        color: $white;
        font-weight: 600;
        margin-bottom: 20px;
        line-height: 114%;

        @media screen and (max-width: 1441px) {
            width: 90%;
        }
        @media screen and (max-width: 1153px) {
            width: 100%;
        }
    }

    .orange {
        color: $orange;
    }

    &__body,
    ul {
        font-family: $titti;
        font-size: 1.125em;
        line-height: 25px;
        color: $white;
        padding-right: 20px;

        ul {
            padding-left: 26px;

            li {
                padding-bottom: 5px;
            }
        }
    }

    &__img-container {
        width: 100%;

        &.tall {
            height: 550px;
        }

        &.medium {
            height: 400px;
        }

        &.short {
            height: 300px;
        }

        img {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            object-fit: cover;
            z-index: 5;
        }
    }

    &__link-container {
        margin-top: 40px;
        svg {
            transition: transform 0.15s ease;
        }
    }

    &__link {
        color: $blue;
        font-size: 1.375em;
        font-family: $inter;
        font-weight: 700;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 7px;

        &:hover {
            .blue-arrow {
                transform: translateX(10px);
            }
        }
    }

    &.light {
        background-color: $white;

        .textImage__header {
            color: black;
        }

        .textImage__body {
            color: black;
        }
    }

    @media screen and (max-width: 768px) {
        &__container,
        .reverse {
            flex-direction: column;
            gap: 2em;
        }

        &__left {
            width: 100%;
        }

        &__right {
            width: 100%;
        }

        &__body {
            padding-right: 0;
        }

        &__link-container {
            margin: 40px 0;
        }
    }
}
