.videoLoop {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(0, 2, 16, 1) 50%
    );

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        max-width: 1500px;
        margin: auto;
        padding-bottom: 100px;
    }

    &__video {
        height: 50.625vw;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 5px;
        max-height: 759px;
        z-index: 6;

        video {
            filter: brightness(0.85);
        }
    }

    &__content {
        position: absolute;
        z-index: 7;
        font-family: $inter;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 70%;
    }

    &__header {
        font-size: 3.75em;
        font-weight: 600;
    }

    &__subheader {
        text-align: center;
        font-size: 1.75em;
        font-weight: 500;
        line-height: 44px;
        opacity: 0.8;
    }

    &__button {
        background-color: $orange;
        padding: 10px 33px;
        border-radius: 27px;
        color: $white;
        text-decoration: none;
    }

    @media screen and (max-width: 1024px) {
        &__video {
            height: 70vh;
        }

        &__container {
            padding-top: 100px;
        }
    }
    @media screen and (max-width: 768px) {
        &__video {
            height: 60vh;

            video {
                align-self: normal;
            }
        }

        &__content {
            width: 90%;
        }

        &__header {
            font-size: 2em;
        }

        &__subheader {
            font-size: 1.5em;
            line-height: 36px;
        }
    }
    @media screen and (max-width: 500px) {
        &__header {
            font-size: 1.7em;
        }

        &__subheader {
            font-size: 1.2em;
            line-height: 30px;
        }
    }
}
