.footer {
    &.mobile {
        display: none;
    }

    padding: 3%;
    background: #000211;

    &__container {
        display: flex;
        flex-direction: row;
        border: 1px solid rgba(255, 255, 255, 0.12);
        max-width: 1500px;
        margin: auto;
    }

    &__left {
        width: 45%;
        border-right: 1px solid rgba(255, 255, 255, 0.12);
        padding: 3%;
    }

    &__header {
        color: $body;
    }

    &__paragraph {
        color: $white;
        font-family: $inter;
    }

    &__middle {
        display: flex;
        flex-direction: column;
        padding: 3% 0 1.5% 0;
        border-right: 1px solid rgba(255, 255, 255, 0.12);
        width: 30%;

        .footer__links {
            padding-bottom: 61px;
        }

        .footer__header {
            padding-left: 40px;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        padding: 3% 0 1.5% 0;
        width: 30%;

        .footer__links {
            padding-bottom: 123px;
        }

        .footer__header {
            padding-left: 40px;
        }
    }

    img {
        width: 138px;
        margin-bottom: 30px;
    }

    &__header {
        font-size: 16px;
        opacity: 0.8;
        font-family: 'Nitti';
        margin-bottom: 15px;
    }

    &__paragraph {
        font-size: 18px;
        line-height: 31px;
        margin-bottom: 20px;
    }

    &__links {
        display: flex;
        flex-direction: column;
        gap: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        padding-left: 40px;
    }
    a {
        font-family: 'Nitti';
        text-decoration: none;
        color: white;

        &:hover {
            color: rgba(62, 199, 76, 1);
            display: flex;
        }
    }

    &__email {
        margin-top: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }

    &__location {
        margin-top: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }

    &__phone {
        margin-top: 20px;
    }

    &__linkedin {
        margin-top: 20px;
        height: 100%;

        a {
            padding-left: 40px;
        }
    }

    &__socials {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        a {
            padding: 0;

            &:hover {
                display: block;

                path {
                    fill: rgba(62, 199, 76, 1);
                }
            }
        }
    }

    &__single-link {
        width: fit-content;
        background-repeat: no-repeat;
        padding-right: 28px;
        background-position: 100% 78%;
        font-weight: 400;

        &:hover {
            background-image: url('https://optimise2.assets-servd.host/hefring-engineering/local/dist/assets/images/footer_arrow.png?w=17&h=12&auto=compress%2Cformat&fit=crop&dm=1685561812&s=6e8a3dd1103b593b21dded0cbb79320a');
        }
    }

    .top-left {
        position: absolute;
        top: 0;
        left: 0;
    }

    .bottom-left {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .top-center {
        position: absolute;
        top: 0;
        right: -10px;
    }

    .center-left {
        position: absolute;
        top: -26.5px;
        left: 0px;
    }

    .center-bottom {
        position: absolute;
        bottom: 0;
        left: -9.5px;
    }

    .center-center {
        position: absolute;
        top: -27px;
        right: -7px;
    }

    .top-right {
        position: absolute;
        right: 0;
        top: 0;
    }

    .right-center {
        position: absolute;
        right: 0;
        top: -27px;
    }

    .right-bottom {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .white {
        color: $white;
    }

    .hs-form-private {
        display: flex;
        gap: 20px;
    }

    .hs_email {
        width: 80%;
    }

    .hs-input {
        width: 100%;
        height: 40px;
        border-radius: 2.5px;
        font-family: $nitti;
        padding-left: 20px;

        &.error {
            padding: 0;
            padding-left: 20px;
        }
    }

    .hs-submit {
        width: 20%;
    }

    .hs-button {
        width: 100%;
        height: 40px;
        font-family: $inter;
        background-color: $orange;
        color: $white;
        border-radius: 2.5px;
        cursor: pointer;
    }

    .submitted-message {
        color: white;
        font-family: $inter;
    }

    .hs_error_rollup {
        display: none;
    }

    .hs-error-msgs {
        font-family: $inter;
        list-style: none;
        color: white;
        padding-left: 0;
        padding-top: 10px;
    }

    @media screen and (max-width: 941px) {
        &__right {
            .footer__links {
                padding-bottom: 155px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        display: none;

        &.mobile {
            display: block;
            font-size: 18px;
        }

        &__container {
            flex-direction: column;
        }

        &__left {
            width: 100%;
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            padding: 5%;
        }
        &__links {
            padding-left: 5%;
        }

        &__middle {
            width: 100%;
        }

        &__right {
            width: 100%;

            .footer__header {
                padding-left: 5%;
            }
        }

        &__email,
        &__location {
            border: none;
        }

        &__email {
            border-top: 1px solid rgba(255, 255, 255, 0.12);
            padding-top: 3%;

            .footer__header {
                margin-bottom: 5px;
            }
        }
        .margin-bottom {
            margin-bottom: 0 !important;
        }

        &__company {
            display: flex;
            gap: 15px;
            flex-direction: column;
            padding-left: 5%;
        }

        &__oceanscout {
            .footer__header {
                border-top: 1px solid rgba(255, 255, 255, 0.12);
                padding-top: 3%;
            }

            .footer__single-link {
                padding-left: 5%;
            }
        }

        &__middle {
            .footer__header {
                padding-left: 5%;
            }

            .footer__links {
                padding: 0;
                border-bottom: 0;
            }
        }

        &__location {
            .footer__header {
                margin-bottom: 5px;
            }
        }

        &__phone {
            .footer__header {
                margin-bottom: 5px;
            }
        }

        &__linkedin {
            padding-bottom: 3%;

            .footer__header {
                padding-left: 5%;
                margin-bottom: 5px;
            }

            a {
            }
        }

        &__socials {
            justify-content: flex-start;
            gap: 14px;
            padding-left: 5%;
        }
    }
}
