.landingHero,
.landingHero.desktop {
    height: 100vh;
    width: 100vw;
    padding-top: 95px;
    max-height: 1000px;
    background-color: white;

    &__container {
        display: flex;
        height: 100%;
        max-width: 1350px;
        margin: auto;
        justify-content: space-between;
        background-image: url('/assets/bg_logo.png');
        background-size: auto 60%;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &__left {
        width: 30%;
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        gap: 20px;
        left: -22%;
        @media screen and (max-width: 1200px) {
            left: -22%;
        }
    }

    &__img-container {
        width: 100px;
        height: 100px;
        opacity: 0;
        border-radius: 10px;
        overflow: hidden;
        z-index: 2;

        img {
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
    }

    &__left1 {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: flex-end;

        .landingHero__img-container {
            &--0 {
                height: 300px;
                width: 240px;
            }

            &--1 {
                width: 300px;
                height: 289px;
            }

            &--2 {
                width: 250px;
                height: 187px;
            }
        }
    }

    &__left2 {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: flex-start;

        .landingHero__img-container {
            &--0 {
                width: 148px;
                height: 175px;
            }

            &--1 {
                width: 225px;
                height: 213px;
            }

            &--2 {
                width: 175px;
                height: 167px;
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 20px;
        right: -19%;
        @media screen and (max-width: 1200px) {
            margin-left: -9vw;
        }
    }

    &__right1 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;

        .landingHero__img-container {
            &--0 {
                height: 193px;
                width: 165px;
            }

            &--1 {
                width: 186px;
                height: 205px;
            }
        }
    }

    &__right2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        .landingHero__img-container {
            &--0 {
                height: 271px;
                width: 236px;
            }

            &--1 {
                width: 263px;
                height: 279px;
            }

            &--2 {
                width: 261px;
                height: 210px;
            }
        }
    }

    &__center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        right: -3%;
    }

    &__header {
        font-size: 3.4em;
        font-family: $inter;
        text-align: center;
        margin-bottom: 35px;
        width: 160%;
        opacity: 0;
        z-index: 2;
    }

    &__btn {
        background-color: #ee5340;
        padding: 10px 33px;
        border-radius: 27px;
        color: #ffffff;
        text-decoration: none;
        font-family: $inter;
        z-index: 4;
        opacity: 0;
    }

    &Mobile {
        display: none;
    }
    // @media screen and (max-width: 1024px) {
    //     &__left {
    //         // left: -31%;
    //     }

    //     &__right {
    //         width: 30%;
    //         right: -3%;
    //     }
    // }
}

@media screen and (max-width: 1440px) {
    .landingHero,
    .landingHero.desktop {
        &__header {
            font-size: 2.8em;
        }

        &__center {
            right: -4%;
            padding-left: 20px;
            padding-right: 20px;
        }

        &__left {
            left: -19%;
        }

        &__right {
            right: -15%;
        }
    }
}

@media screen and (max-width: 1250px) {
    .landingHero,
    .landingHero.desktop {
        &__center {
            right: 3%;
            padding-left: 20px;
            padding-right: 20px;
        }

        &__left {
            left: -16%;
        }

        &__right {
            right: -13%;
        }

        &__header {
            width: 130%;
        }

        &__left1 {
            .landingHero__img-container {
                &--0 {
                    width: 240px;
                    height: 250px;
                }

                &--1 {
                    width: 165px;
                    height: 155px;
                }

                &--2 {
                    width: 115px;
                    height: 107px;
                }
            }
        }

        &__left2 {
            .landingHero__img-container {
                &--0 {
                    width: 105px;
                    height: 98px;
                }

                &--1 {
                    width: 145px;
                    height: 163px;
                }

                &--2 {
                    width: 115px;
                    height: 107px;
                }
            }
        }

        &__right1 {
            .landingHero__img-container {
                &--0 {
                    width: 133px;
                    height: 125px;
                }

                &--1 {
                    width: 140px;
                    height: 155px;
                }
            }
        }
        &__right2 {
            .landingHero__img-container {
                &--0 {
                    width: 199px;
                    height: 186px;
                }

                &--1 {
                    width: 118px;
                    height: 111px;
                }

                &--2 {
                    width: 193px;
                    height: 181px;
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .landingHero,
    .landingHero.desktop {
        &__header {
            font-size: 2.3em;
        }
    }
}

@media screen and (max-width: 1024px) {
    .landingHero.desktop {
        display: none;
    }

    .landingHeroMobile {
        display: block;
        background-color: white;

        &__right {
            margin-left: unset;
        }

        &__top {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            background-image: url('/assets/bg_logo.png');
            background-size: auto 70%;
            background-repeat: no-repeat;
            background-position: center 100%;
            height: 50vh;
        }

        &__header {
            font-family: $inter;
            text-align: center;
            width: 80%;
            margin-bottom: 35px;
            font-size: 2.95em;
            line-height: 47px;
        }

        &__btn {
            background-color: #ee5340;
            padding: 10px 33px;
            border-radius: 5px;
            color: #ffffff;
            text-decoration: none;
            font-family: $inter;
            bottom: -20px;
            z-index: 1;
        }

        &__bottom {
            display: flex;
            flex-direction: row;
        }

        &__col1,
        &__col2,
        &__col3 {
            width: 30%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        &__col1 {
            left: -15px;

            .landingHeroMobile__img-container {
                &--0 {
                    height: 175px;
                }

                &--1 {
                    height: 190px;
                }
            }
        }

        &__col2 {
            .landingHeroMobile__img-container {
                &--0 {
                    height: 157px;
                }

                &--1 {
                    height: 172px;
                }
                &--2 {
                    height: 132px;
                }
            }
        }

        &__col3 {
            width: 40%;
            right: -15px;

            .landingHeroMobile__img-container {
                &--0 {
                    height: 205px;
                }

                &--1 {
                    height: 220px;
                }
            }
        }

        &__img-container {
            width: 100%;
            height: 100px;
            transition: 0.3s;
            border-radius: 10px;
            overflow: hidden;

            img {
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
            }
        }

        @media screen and (max-width: 768px) {
            &__top {
                background-size: auto 100%;
                height: 30vh;
            }
            &__header {
                font-size: 1.75em;
                line-height: 35px;
            }
        }
    }
}
