.imageAnimate {
    font-family: $inter;
    color: $white;
    background-color: #000210;
    padding: 0%;

    &__container {
        height: 100vh;
        display: flex;
        align-items: center;
        max-width: 1500px;
        margin: auto;
        max-height: 700px;
        padding: 0 5%;
    }

    &__right {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
    }

    &__right-container {
        display: none;
    }

    &__left {
        width: 50%;
        margin: 0 5% 0 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
    }

    &__wrapper {
        max-width: 512px;
        max-height: 560px;
        opacity: 1;
        position: absolute;
        margin: auto;
        border-radius: 10px;
        display: flex;
        align-items: flex-end;
        margin-left: 5%;
        height: 50vh;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: 65% 0 !important;
    }

    &__video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    &__text {
        margin: 0 0 20px 20px;
    }

    &__title {
        font-family: 'lato', sans-serif;
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 10px;
    }

    &__body {
        font-size: 20px;
        line-height: 31.9px;
        width: 95%;
        opacity: 0.75;
    }

    &__static-header,
    &__static-subheader {
        font-family: 'lato', sans-serif;
        font-weight: 800;
        font-size: 45px;
        line-height: 61px;
    }

    &__static-header {
        margin-bottom: -5px;
    }

    &__static-subheader {
        margin-bottom: 20px;
        color: rgba(138, 139, 145, 1);
    }

    &__link {
        color: $orange;
        border: 1px solid $orange;
        padding: 10px 33px;
        border-radius: 27px;
        margin-top: 20px;
        text-decoration: none;

        &:after {
            color: $orange;
        }

        &:visited {
            color: $orange;
        }
    }

    @media screen and (max-width: 1024px) {
        &__static-header,
        &__static-subheader {
            font-size: 33px;
            line-height: 46px;
        }

        &__body {
            font-size: 1em;
            line-height: 27.9px;
        }
    }

    @media screen and (max-width: 768px) {
        .desktop {
            display: none;
        }

        &__container {
            flex-direction: column;
            gap: 25px;
            height: fit-content;
            max-height: none;
            padding: 0 5%;
        }

        &__left,
        &__right {
            width: 100%;
        }

        &__left {
            margin: 0 5%;
        }

        &__right-container {
            display: block;
            width: 100%;
            overflow-x: scroll;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }

        &__right {
            flex-direction: row;
            gap: 20px;
            width: 250%;
            &.mobile {
                display: flex;
            }

            &.mobileVideo {
                width: 100%;
            }
        }

        &__right-container::-webkit-scrollbar {
            width: 0;
        }

        &__static-header,
        &__static-subheader {
            font-size: 1.5em;
            line-height: 40px;
        }

        &__static-subheader {
            margin-bottom: 20px;
        }

        &__body {
            font-size: 1.125em;
        }

        &__wrapper {
            position: relative;
            height: 38vh;
            width: 90vw;
            opacity: 1;
            position: unset;
            border-radius: 10px;
            margin-left: 3%;
            min-width: 300px;
            margin: auto;
            max-width: unset;

            .imageAnimate__body {
                font-size: 1em;
                line-height: 23px;
            }
        }

        &__title {
            font-size: 1.25em;
        }
    }
}
