.entrySlider {
    padding: 50px 3%;
    font-family: $inter;
    background-color: $white;
    height: 760px;

    &__container {
        max-width: 1330px;
        margin: auto;
        height: 100%;
    }

    &__slider-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 320px;
        gap: 20px;
    }

    &__top {
        display: flex;
        justify-content: space-between;
    }

    &__nav {
        display: flex;
        gap: 40px;
        align-items: flex-start;

        * {
            cursor: pointer;
        }
    }

    &__header {
        font-size: 2.25em;
        margin-bottom: 2%;
        color: black;
    }

    &__link {
        text-decoration: none;
        color: $white;

        &::after {
            color: white;
        }

        &:visited {
            color: white;
        }

        svg {
            transition: transform 0.15s ease;
        }

        &:hover {
            svg {
                transform: translateX(10px);
            }
        }
    }

    &__tag {
        color: white;
        background-color: #3ec74c;
        font-size: 18px;
        width: fit-content;
        padding: 15px;
        border-radius: 5px;
    }

    &__slide {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        border-radius: 10px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: 65% 0 !important;
        padding: 25px;
    }

    &__content {
        color: black;
    }

    &__title {
        font-size: 30px;
        line-height: 42px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 1.25rem;
    }

    &__paragraph {
        font-size: 18px;
        line-height: 143%;
        margin-bottom: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        opacity: .8;
    }

    &__learn-more-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }

    &__learn-more {
        font-size: 18px;
        font-weight: 600;
    }

    .disabled {
        opacity: 0.5;
        cursor: none;
    }

    @media screen and (max-width: 768px) {
        padding: 5% 5% 100px 5%;
        height: 850px;

        &__slider-container {
            height: 450px;
        }
    }

    @media screen and (max-width: 500px) {
        padding-top: 40px;
        padding-bottom: 50px;
        height: 680px;

        &__slider-container {
            height: 350px;
            margin-top: 20px;
        }

        &__title {
            font-size: 1em;
            line-height: 21px;
        }

        &__paragraph {
            font-size: 0.75em;
            line-height: 17px;
        }

        &__learn-more {
            font-size: 0.75em;
        }
    }
}
