.slider {
    &__container {
        height: 100vh;
        background: linear-gradient(
            180deg,
            rgba(3, 49, 69, 1) 50%,
            rgba(0, 2, 17, 1) 50%
        );
        padding: 5%;
        padding-top: 115px;
        padding-right: 0;
        width: 100%;
        padding: 115px 0 5% 5%;
    }

    &__header {
        padding-right: 21px;
        background: linear-gradient(
            90.97deg,
            rgba(20, 153, 161, 1),
            rgba(52, 104, 177, 1)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 700;
        font-size: 60px;
        margin-bottom: 40px;
        line-height: normal;
        font-family: $inter;

        @media screen and (max-height: 1434px) {
            width: 77%;
        }

        @media screen and (max-height: 1200px) {
            width: 90%;
        }
    }

    .mobile {
        display: none;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-grow: 0;
        gap: 5%;
        color: $white;
        font-family: $inter;
    }

    &__item {
        min-width: 600px;
        height: 70vh;
        width: 70vh;
        max-height: 600px;
        background-color: rgba(20, 26, 37, 1);
        box-shadow: 0px 4px 50px -15px rgba(0, 61, 192, 0.25);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 0 0 2% 2%;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: 65% 0 !important;

        @media screen and (max-height: 850px) {
            height: 60vh;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 10px;
    }

    &__paragraph {
        color: rgba(255, 255, 255, 0.8);
        font-size: 22px;
        line-height: 34px;
        margin-right: 10%;
    }

    @media screen and (max-width: 768px) {
        .desktop {
            display: none;
        }

        .mobile {
            display: flex;
        }

        &__container {
            padding-left: 0;
            height: auto;
        }

        &__header {
            margin-left: 5%;
            font-size: 40px;
        }

        &__wrapper-container {
            width: 100%;
            overflow-x: scroll;
            background: linear-gradient(180deg, #033145 46%, #000211 46%);
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }

        &__wrapper-container::-webkit-scrollbar {
            width: 0;
        }

        &__wrapper {
            gap: 1%;
            padding-left: 5%;
            padding-right: 5%;
        }

        &__item {
            min-width: 400px;
            height: 50vh;
            width: 60vw;
            padding: 0 0 1% 1%;
        }

        &__title {
            font-size: 22px;
            margin-bottom: 0;
        }

        &__paragraph {
            font-size: 16px;
            line-height: 26px;
        }
    }

    @media screen and (max-width: 500px) {
        &__header {
            margin-bottom: 50px;
            margin-top: 60px;
        }

        &__item {
            width: 94vw;
            min-width: unset;
        }
    }
}
