.video {
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        position: relative;
        background-color: #000211;
        padding-top: 95px;
    }

    &__glider-container {
        rotate: 90deg;
        left: -75%;
        position: absolute;
    }

    &__video {
        background-color: #000211;
        height: 95%;
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 10px;
    }

    &__video-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text {
        font-size: 50px;
        position: absolute;
        opacity: 0;
        z-index: 1;
        font-family: $inter;
        color: $white;
    }

    .video-background {
        align-self: normal;
    }

    @media screen and (max-width: 768px) {
        &__container {
            height: 40vh;
            padding-top: 0;
        }

        .video-background {
            border-radius: 10px;
        }
    }
    
    @media screen and (max-width: 500px) {
        &__text {
            font-size: 38px;
        }
    }
}
