// COLORS
$green: #3EC74C;
$white: #FFFFFF;
$body: rgba(255, 255, 255, .8);
$orange: rgb(238, 83, 64);
$blackBg: rgb(33, 34, 39);
$greyBg: rgb(245, 245, 245);
$blue: rgb(36, 98, 222);
$blackHeader: rgb(58, 58, 58);

// FONTS
$inter: 'Inter', sans-serif;
$nitti: 'Nitti', sans-serif;
$titti: "titillium-web", sans-serif;
$opensans: 'Open-Sans', sans-serif;
$mont: "montserrat", sans-serif;