.gallery {
    background-color: $blackBg;

    &__container {
        max-width: 1500px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 60px 10%;
        gap: 1em;
    }

    &__header {
        color: $orange;
        font-family: $titti;
        font-size: 2.375em;
        font-weight: 600;
        margin-bottom: 20px;
    }

    &__subheader {
        color: $white;
        line-height: 28px;
        font-weight: 400;
        font-family: $titti;
        font-size: 1.25em;
        margin-bottom: 20px;
    }

    &__images-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__largeImg-container {
        width: 100%;
        height: 600px;
    }

    &__img-container {
        height: 250px;
        width: 33%;
    }

    &__images-bottom {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    &__img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        z-index: 5;
    }
        @media screen and (max-width: 768px) {
            &__images-bottom {
                flex-direction: column;
            }

            &__largeImg-container {
                height: 350px;
            }

            &__img-container {
                width: 100%;
                height: 350px;
            }
        }
}
