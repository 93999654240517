.contact {
    &__container {
        max-width: 1500px;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 60px 10%;
        gap: 1em;
    }

    &__left {
        width: 50%;
    }

    &__right {
        width: 50%;
    }

    &__header {
        font-family: $titti;
        font-size: 2.875em;
        font-weight: 600;
        margin-bottom: 25px;
    }

    &__info-container {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        gap: 10px;
    }

    &__info {
        font-family: $titti;
        font-size: 1.75em;
        margin-bottom: 20px;
        font-weight: 400;
    }

    &__svg {
        padding-top: 2px;
    }

    label {
        font-size: 1em;
        font-family: $opensans;
        color: rgb(33, 34, 39);
        font-weight: 500;
    }

    input,
    textarea {
        border: 1px solid rgb(221, 221, 221);
        height: 45px;
        padding: 5px 0;
        width: 100%;
        background-color: rgb(250, 250, 250);
        margin: 10px 0 20px;
        font-family: $inter;
    }

    textarea {
        height: 200px;
    }

    .hs-form-required {
        color: red;
        margin-left: 3px;
    }

    .hs-button {
        width: 30%;
        background-color: $blue;
        border-radius: 100px;
        text-transform: uppercase;
        color: $white;
        font-family: $opensans;

        &:hover {
            background-color: rgba(36, 98, 222, 0.63);
        }
    }

    @media screen and (max-width: 768px) {
        &__container {
            flex-direction: column;
            gap: 2.5em;
        }

        &__left {
            width: 100%;
        }

        &__right {
            width: 100%;
        }
    }
}
