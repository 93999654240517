.hero {
    height: 700px;
    width: 100%;
    margin-top: 95px;

    &__img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        z-index: 5;
        object-position: top center;
    }

    @media screen and (max-width: 1024px) {
        height: 450px;
    }
    @media screen and (max-width: 768px) {
        margin-top: 0;
    }
}
