.home {
    overflow-x: hidden;
    background-color: #000210;

    .textImage {
        background-color: #000210;
        &__container {
            gap: 8%;
            padding: 60px 5% 0;

            @media screen and (min-width: 1500px) {
                padding: 60px 0 0;
                max-width: 1350px;
            }
        }

        &__header {
            font-size: 3.06em;
        }

        &__body {
            line-height: 31.9px;
            font-size: 1.25em;
            opacity: .75;
        }

        &__img-container {
            img {
                border-radius: 5px;
            }
        }

        .orange {
            color: #8A8B91
        }

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .nav {
        padding: 0 5%;

        @media screen and (max-width: 768px) {
            padding: 30px;
        }
    }

    @media screen and (max-width: 768px) {
        .textImage {
            &__container {
                flex-direction: column-reverse;
                gap: 30px;
                padding: 80px 5%;
            }

            &__img-container {
                height: 350px;
            }

            &__header {
                font-size: 1.9em;
                line-height: 40px;
                margin-bottom: 15px;
            }

            &__body {
                font-size: 1em;
                line-height: 28px;
            }

            &__link {
                font-size: 1em;

                &-container {
                    margin: 25px 0;
                }
            }
        }
    }
}

.jump-link {
    top: -110px;
}
