.grid {
    height: 130vh;
    background: #000211;
    padding: 150px 3%;
    font-family: $inter;

    &__container {
        max-width: 1500px;
        margin: auto;
        height: 100%;
    }

    &__header {
        font-size: 60px;
        margin-bottom: 2%;
        color: $white;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    &__cta {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        margin-bottom: 24px;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
    }

    &__grid {
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        gap: 19px;
    }

    &__link {
        width: 49%;
        color: white;
        text-decoration: none;
        border-radius: 10px;
    }

    &__tag {
        color: white;
        background-color: #3ec74c;
        font-size: 18px;
        width: fit-content;
        padding: 15px;
        border-radius: 5px;
        margin: 3% 0 0 3%;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        border-radius: 10px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: 65% 0 !important;
    }

    &__content {
        margin: 0 3% 5% 3%;
    }

    &__title {
        font-size: 30px;
        line-height: 42px;
        font-weight: 700;
        margin-bottom: 10px;
        width: 70%;
    }

    &__paragraph {
        font-size: 18px;
        line-height: 143%;
        margin-bottom: 20px;
    }

    &__learn-more-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }

    &__learn-more {
        font-size: 18px;
        font-weight: 600;
    }

    .header {
        &__quote {
            color: rgba(237, 83, 63, 1);
            border: 1px solid #ed533f;
            border-radius: 5px;
            padding: 10px 20px;
            margin-left: 2%;
            font-weight: 500;
            cursor: pointer;
            left: -10px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .grid {
        &__grid {
            gap: 14px;
        }

        &__header {
            font-size: 44px;
        }

        &__cta {
            font-size: 20px;
        }

        &__title {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 5px;
        }

        &__paragraph {
            line-height: 30px;
        }
    }
}

@media screen and (max-width: 768px) {
    .grid {
        height: 100%;
        padding-bottom: 40px;
        padding-top: 20%;

        &__header {
            font-size: 40px;
            margin-bottom: 20px;
        }

        &__grid {
            gap: 30px;
        }

        &__link {
            width: 100%;
            height: 500px;
        }
    }
}
