.clickSwitch {
    height: 60vh;
    background: #000211;
    padding-top: 4vh;
    font-family: $inter;

    .mobile {
        display: none;
    }

    &__container {
        display: flex;
        flex-direction: row;
        height: 100%;
        gap: 20px;
        width: 90%;
        max-width: 1500px;
        margin: auto;
    }

    &__left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        justify-content: center;
    }

    &__block {
        height: fit-content;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.4);
        transition: 0.3s;
    }

    &__header {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .active-block {
        color: white;

        h1 {
            font-weight: 700;
        }
    }

    &__paragraph {
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
    }

    &__right {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: end;
        justify-content: center;
    }

    &__img-container {
        width: 42vw;
        height: 53vh;
        position: absolute;
        opacity: 0;
        transition: 0.3s;
        border-collapse: separate;
        border-radius: 10px;
        overflow: hidden;
        max-width: 700px;

        img {
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
    }

    .active-img {
        opacity: 1;
        z-index: 2;
    }
}
@media screen and (max-width: 768px) {
    .clickSwitch {
        height: unset;

        .desktop {
            display: none;
        }

        &__left {
            width: 100%;
            pointer-events: none;
            color: white;
        }

        &__block {
            color: white;
        }

        &__paragraph {
            margin-bottom: 20px;
        }

        &__img-container.mobile {
            display: block;
            position: relative;
            opacity: 1;
            height: 350px;
            width: 100%;
            margin-bottom: 60px;
        }
    }
}
