.oceanscoutHero {
    color: $white;
    background-color: rgb(0, 2, 16);
    height: 100vh;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: 50% 28vh;
    font-family: $inter;

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        top: 50%;
        transform: translateY(-50%);
    }

    &__header {
        font-size: 4.5em;
        font-weight: 500;
    }

    &__subheader {
        opacity: 0.8;
        font-size: 28px;
        font-weight: 300;
        width: 50%;
        text-align: center;
        margin-top: 20px;
        line-height: 44px;
    }

    @media screen and (max-width: 1024px) {
        &__container {
            text-align: center;
        }

        &__subheader {
            width: 80%;
        }
    }

    @media screen and (max-width: 768px) {
        height: 50vh;
        background-image: none !important;

        &__header {
            font-size: 1.875em;
        }

        &__subheader {
            font-size: 1.1em;
            line-height: 26.28px;
        }
    }
}
