.nav {
    display: flex;
    align-items: center;
    height: 95px;
    background-color: white;
    width: 100%;
    max-width: 1500px;
    padding: 0 10%;
    margin: auto;
    opacity: 0;

    &.oceanscout {
        @media screen and (max-width: 1024px) {
            .nav__a {
                font-size: 0.8em;
            }

            .nav__left,
            .nav__right {
                gap: 0.9em;
            }
        }
    }

    &__mobile {
        display: none;
    }

    &__logos {
        width: 110px;
    }

    .logo {
        width: 100%;
    }

    &__container {
        top: 0;
        z-index: 100;
        position: fixed;
        background-color: $white;
        width: 100%;
    }

    &__ul {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__left,
    &__ul,
    &__right {
        display: flex;
        align-items: center;
        gap: 1.8rem;
    }

    &__ul {
        width: 100%;
        justify-content: space-between;
    }

    &__li {
        list-style-type: none;
    }

    &__a {
        color: black;
        font-size: 1em;
        text-decoration: none;
        font-family: $inter;
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -2px;
            width: 0px;
            height: 2px;
            background: black;
            margin-bottom: -5px;
            transition: all 0.45s;
        }

        &:hover::after {
            width: 100%;
            left: 0;
        }
    }

    .discover-oceanscout {
        display: none;
    }

    .close-nav {
        display: none;
    }

    .request-a-quote {
        background-color: #ee5340;
        padding: 10px 33px;
        border-radius: 27px;
        color: #ffffff;
        text-decoration: none;
        font-family: $inter;
    }

    .request-a-quote-a {
        &:after {
            position: absolute;
            left: 0%;
            width: 0px;
            height: 0px;
            background: none;
            margin-bottom: 0px;
        }

        &:hover::after {
            width: 0%;
            left: 0;
            background: none;
        }
    }

    @media screen and (max-width: 768px) {
        overflow-y: hidden;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        padding: 30px;
        gap: 30px;
        opacity: 1;

        &__container {
            opacity: 0;
            z-index: 0;
        }

        &__mobile {
            display: flex;
            z-index: 1000;
            justify-content: space-between;
            padding: 30px;
            padding-right: 10px;
            align-items: center;
            z-index: 1;
            background-color: white;

            .logo {
                width: 61%;
            }
        }

        &__logos {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }

        &__logo {
            width: 23%;
        }

        .close-nav {
            display: unset;
            cursor: pointer;
        }

        &__ul,
        &__left,
        &__right {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            flex-direction: column;
            padding: 0;
            width: 100%;
        }

        &__li {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 20px;
            background-image: url('/assets/nav_arrow.svg');
            background-repeat: no-repeat;
            background-position-x: 100%;
            background-position-y: 26%;
        }

        .request-a-quote {
            background-image: url('/assets/nav_arrow.svg');
            background-repeat: no-repeat;
            background-position-x: 100%;
            background-position-y: 26%;
            background-color: white;
            padding: 0;
            border-radius: 0;
            color: black;
        }

        &__a {
            font-size: 1.375em;

            &:hover::after {
                width: 0%;
                left: 0;
            }
        }

        .oceanscout-desktop {
            display: none;
        }

        .discover-oceanscout {
            display: unset;
            background-color: #ed533f;
            border: none;
            padding: 20px 0;
            background-image: none;
            text-align: center;
            border-radius: 5px;

            a {
                font-size: 1em;
                color: $white;
            }
        }

        .contact {
            border: none;
        }
    }
    @media screen and (max-width: 500px) {
        &__logo {
            width: 38%;
        }
    }
}

.dark {
    color: $white;

    .nav {
        background-color: black;

        &__container {
            background-color: black;
        }

        &__a {
            color: white;
            font-size: 1em;
            text-decoration: none;
            font-family: $inter;
            &:after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -2px;
                width: 0px;
                height: 2px;
                background: white;
                margin-bottom: -5px;
                transition: all 0.45s;
            }

            &:hover::after {
                width: 100%;
                left: 0;
            }
        }

        .request-a-quote-a {
            &:after {
                background: none;
            }

            &:hover::after {
                width: 0%;
                left: 0;
                background: none;
            }
        }

        @media screen and (max-width: 768px) {
            &__mobile {
                background: black;
            }

            &__li {
                background-image: url('/assets/arrow_dark.svg');
            }

            .request-a-quote {
                height: 0;
                background-image: unset;
                background-color: black;
                padding: 0;
                border-radius: 0;
                z-index: 1;
                color: white;
            }

            .discover-oceanscout {
                background-image: none;
            }

            &__a {
                &:hover::after {
                    width: 0%;
                    left: 0;
                }
            }
        }
    }
}
