.splitHeaderBody {
    &__container {
        max-width: 1500px;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 60px 10%;
        gap: 1em;
    }

    .reverse {
        flex-direction: row-reverse;
        gap: 30px;
    }

    &__left {
        width: 50%;
    }

    &__right {
        width: 50%;
    }

    &__header {
        font-family: $titti;
        font-size: 2.876em;
        font-weight: 600;
        line-height: 60px;
    }

    &__body {
        p {
            font-family: $titti;
            font-size: 1.375em;
            line-height: 30px;
        }
    }

    &__btn {
        width: fit-content;
        background: $blue;
        color: white;
        padding: 12px 24px;
        border-radius: 25px;
        font-family: $opensans;
        font-size: 1em;
        margin-top: 20px;
        transition: .3s ease;
        border: 1px solid $blue;

        &:hover {
            background-color: $white;
            color: black;
            border: 1px solid black;
        }
    }

    &__anchor {
        text-decoration: none;

        &::after {
            text-decoration: none;
        }

        &:visited {
            text-decoration: none;
        }
    }

    .orange {
        color: $orange;
    }

    @media screen and (max-width: 768px) {
        &__container,
        .reverse {
            flex-direction: column;
            gap: 10px;
        }

        &__left {
            width: 100%;
        }

        &__right {
            width: 100%;
        }
    }
}
