.scrollFade,
.dissect {
    &__container {
        height: 100vh;
        background-color: rgb(0, 2, 16);
        margin: auto;
        padding-top: 105px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        font-family: $inter;
        color: $white;
    }

    .first-scroll {
        box-shadow: inset 0 0 100px 100px #000210;
    }

    &__container-wrapper {
        max-width: 1500px;
        margin: auto;
    }

    &__header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    &__subheader {
        color: #3ec74c;
        font-family: 'Nitti';
        font-weight: 400;
        font-style: normal;
        font-size: 28px;
    }

    &__header {
        font-size: 53px;
        max-width: 663px;
        text-align: center;
        color: rgba(138, 139, 145, 1);

        .white {
            color: white;
        }
    }

    &__bottom {
        display: flex;
        margin: auto;
        width: 80%;
        margin-top: 15%;
        max-width: 1500px;
        gap: 50px;

        @media screen and (max-height: 900px) {
            margin-top: 10% !important;
        }
    }

    &__img-container {
        width: 100%;
        height: 300px;
        position: absolute;
        top: -100px;
        opacity: 0;
    }

    &__left {
        width: 50%;
        display: flex;

        img {
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: contain;
        }
    }

    &__right {
        width: 50%;
    }

    &__paragraph {
        opacity: 1;
        position: absolute;
    }

    &__title {
        color: #3ec74c;
        font-family: 'Nitti';
        font-weight: 500;
        font-style: normal;
        font-size: 24px;
        margin-bottom: 10px;
    }

    &__body {
        line-height: 32.13px;
        font-size: 18px;
    }
}

.dissect {
    &__container {
        height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-image: none;
        background-color: unset;
    }

    &__top {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        height: 20%;
    }

    &__subheader {
        font-family: 'Nitti';
        color: #3ec74c;
        font-size: 28px;
    }

    &__header {
        font-size: 50px;
        color: rgba(138, 139, 145, 1);
    }

    .white {
        color: white;
    }

    &__bottom {
        display: flex;
        flex-direction: column-reverse;
        max-width: 1100px;
    }

    &__img-container {
        opacity: 1;
        height: 64vh;
        top: -280px;
        right: -620px;

        @media screen and (max-height: 900px) {
            top: -180px;
        }

        @media screen and (max-width: 1200px) {
            right: -600px;
            top: -190px;
        }
        @media screen and (max-width: 1024px) {
            right: -400px;
        }
    }

    &__left {
        width: 50%;

        img {
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: contain;
        }
    }

    &__right {
        width: 50%;
    }
}

@media screen and (max-width: 1024px) {
    .scrollFade,
    .dissect {
        &__body {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 768px) {
    .scrollFade,
    .dissect {
        &__container {
            height: unset;
            width: 100vw;
            background-image: none !important;
        }

        &__bottom {
            margin-top: 5%;
            gap: 20px;
            flex-direction: column;
            width: 90%;
        }

        &__img-container {
            opacity: 1;
            position: relative;
            &.mobile {
                display: none;
            }
        }

        &__left {
            margin: unset;
            width: unset;

            img {
                position: relative;
                height: 100%;
                margin-top: 57px;
            }
        }

        &__right {
            width: 100%;
        }

        &__paragraph {
            position: unset;
            margin-bottom: 60px;
        }

        &__body {
            font-size: 18px;
        }

        &__title {
            font-size: 22px;
        }
    }

    .dissect {
        &__top {
            top: 50px;
        }

        &__img-container {
            top: 0;
            right: -43px;
            transform: rotate(62deg);
            height: 350px;
        }
    }
}

@media screen and (max-width: 500px) {
    .scrollFade,
    .dissect {
        &__subheader {
            font-size: 20px;
        }

        &__header {
            font-size: 28px;
            line-height: 37px;
        }
    }
}
