.search {
    padding: 145px 50px 100px;

    background: $greyBg;

    &__container {
        max-width: 1170px;
        margin: auto;
    }

    &__container-input {
        background-color: rgb(160, 160, 160);
        padding: 5em 6.67em 3.3em;
        margin-bottom: 1.5em;
    }

    &__results-header {
        color: $blue;
        font-family: $mont;
        font-size: 2.7em;
        font-weight: 500;
    }

    &__container-no-results {
        background: $white;
        padding: 5.34em 6.67em;
    }
    &__no-results {
        margin-bottom: 1.75em;
        font-family: $opensans;
        line-height: 22px;
    }

    ul {
        padding-left: 0;
    }

    @media screen and (max-width: 768px) {
        padding-top: 100px;

        &__container-input,
        &__container-no-results {
            padding: 3em 1em;
        }
    }
}
