.newsLanding {
    background-color: $greyBg;
    padding: 40px;
    padding-top: 115px;
    display: flex;

    &__container {
        max-width: 1200px;
        margin: auto;
        display: inline-grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
    }

    &__link {
        text-decoration: none;
    }

    &__article {
        background-color: $white;
        padding: 20px;
        height: 650px;
        box-sizing: border-box;
    }

    &__img {
        width: 100%;
        margin-bottom: 15px;
    }

    &__header {
        color: $blackHeader;
        font-family: $mont;
        font-size: 1.875em;
        line-height: 39px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    &__category {
        color: $blue;
        font-family: $opensans;
        font-size: 0.94em;
        font-weight: 300;
        margin-bottom: 10px;
    }

    &__body {
        color: $blackHeader;
        font-size: 0.94em;
        font-family: $opensans;
        line-height: 30px;
        margin-bottom: 10px;

        strong {
            font-weight: 400;
        }
    }

    &__read-more {
        font-family: $inter;
        display: flex;
        align-items: center;
        gap: 5px;
        color: $blue;
    }

    .arrows {
        font-size: 10px;
    }

    &__img-container {
        width: 100%;
        height: 300px;

        img {
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: contain;
        }
        @media screen and (max-width: 768px) {
            height: 200px;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 40px 15px;

        &__container {
            display: flex;
            flex-direction: column;
        }

        &__article {
            height: 650px;
            padding: 15px;
        }

        &__header {
            line-height: 30px;
            font-size: 30px;
        }

        &__body {
            font-size: 0.875em;
            line-height: 27px;
        }
    }

    @media screen and (max-width: 500px) {
        &__article {
            height: 510px;
        }
    }
}

.first {
    grid-column: 1/3;
}

.news-landing {
    .nav {
        max-width: 1200px;
        padding: 0;
        @media screen and (max-width: 1300px) {
            padding: 0 40px;
        }

        @media screen and (max-width: 768px) {
            padding: 30px;
        }
    }
}
