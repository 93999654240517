.imageFade {
    font-family: $inter;
    color: $white;

    &__container {
        height: 100vh;
        display: flex;
        align-items: center;
        max-width: 1500px;
        margin: auto;
        padding-top: 95px;
    }

    &__left-container {
        display: none;
    }

    &__left {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 50px;
    }

    .mobile {
        display: none;
    }

    &__right {
        width: 50%;
        margin: 0 5%;
        top: -8%;
        margin-bottom: 20px;
    }

    &__wrapper {
        max-width: 512px;
        max-height: 560px;
        opacity: 1;
        position: absolute;
        margin: auto;
        border-radius: 10px 10px 100px 10px;
        display: flex;
        align-items: flex-end;
        opacity: 0;
        margin-left: 5%;
        height: 50vh;
        width: 100%;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: 65% 0 !important;
    }

    .first {
        background-position: 10% !important;
        background-repeat: no-repeat !important;
        background-size: 100% !important;
    }

    &__text {
        margin: 0 0 20px 20px;
    }

    &__text-under-header {
        opacity: 0;
        position: absolute;
        margin-left: 4px;
    }

    &__title {
        font-family: 'lato', sans-serif;
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 10px;
    }

    &__body {
        font-size: 20px;
        line-height: 31.9px;
        width: 95%;
        opacity: 0.6;
    }

    &__static-header {
        font-family: 'lato', sans-serif;
        font-weight: 800;
        font-size: 3.475rem;
        line-height: 61px;
        margin-bottom: 25px;
        color: white;
    }

    &__static-subheader {
        font-weight: 500;
        font-size: 18px;
        line-height: 38px;
    }

    @media screen and (max-width: 1200px) {
        &__wrapper {
            height: 46vh;
        }
    }

    @media screen and (max-width: 1024px) {
        &__static-header {
            font-size: 32px;
            line-height: 48px;
        }

        &__static-subheader {
            line-height: 31px;
        }

        &__wrapper {
            height: 40vh;
        }
        &__title {
            font-size: 24px;
        }

        &__body {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 890px) {
        &__wrapper {
            height: 36vh;
        }
    }

    @media screen and (max-width: 768px) {
        .imageFade {
            height: 80vh;
            width: 100vw;

            &__container {
                flex-direction: column-reverse;
                height: unset;
                gap: 30px;
                padding-top: 0;
            }

            &__left-container {
                width: 100% !important;
                overflow-x: scroll;
                padding-right: 20px;
                gap: 20px;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                &.mobile {
                    display: flex;
                }
            }

            &__left-container::-webkit-scrollbar {
                width: 0;
            }

            &__left {
                width: 150%;
                justify-content: space-evenly;
                padding-right: 5%;
                margin-left: 0;
            }

            &__wrapper {
                height: 41vh;
                width: 76vw;
                opacity: 1;
                position: unset;
                border-radius: 10px;
                min-width: 300px;
                margin: 0 3% 20px;
                max-width: unset;
            }

            &__text {
                margin: 0 0 15px 13px;
            }

            &__text-under-header {
                display: none;
            }

            &__title {
                font-size: 22px;
                margin-bottom: 5px;
            }

            &__body {
                font-size: 16px;
                line-height: 26.9px;
                color: white;
            }

            &__right {
                width: 90%;
                top: 0;
            }
        }

        .desktop {
            display: none !important;
        }
    }

    @media screen and (max-width: 500px) {
        &__left {
            width: 245%;
        }
    }
}
